import React from "react"
import { StaticQuery, graphql } from "gatsby"

import PostLink from "./post-link"

/**
 *
 * This compontent is used in the /blog page to link to all the piping hot content
 *
 */
function addDraftBanner(x: boolean) {
  if (x) {
    return <b>DRAFT</b>
  } else {
    return null
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                unixtimestamp: date(formatString: "X")
                path
                title
                keywords
                bigImage
                smallImage
                draft
                kill
              }
            }
          }
        }
        allGalleriesYaml(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                unixtimestamp: date(formatString: "X")
                path
                title
                draft
                kill
              }
            }
          }
        }
        allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                unixtimestamp: date(formatString: "X")
                path
                title
                draft
                kill
              }
            }
          }
        }
      }
    `}
    //TODO: add small side images for posts?
    render={(data) => {
      let postsToProcess = data.allMarkdownRemark.edges.concat(
        data.allGalleriesYaml.edges,
        data.allMdx.edges
      )
      console.log("env:" + process.env.NODE_ENV)
      return postsToProcess
        .filter((edge) => !!edge.node.frontmatter.date && !edge.node.frontmatter.kill)
        .filter((edge) => !(edge.node.frontmatter.draft && process.env.NODE_ENV !== "development"))
        .sort((x, y) => y.node.frontmatter.unixtimestamp - x.node.frontmatter.unixtimestamp)
        .map((edge) => (
          <div key={edge.node.id}>
            <PostLink key={edge.node.id} post={edge.node} />
            {addDraftBanner(edge.node.frontmatter.draft)}
            <p>{edge.node.excerpt}</p>
          </div>
        ))
    }}
  />
)
