import React from "react"

import SEO from "../components/seo"

import PostListing from "../components/post-listing"
import Layout from "../components/layout"

// lists of posts and piping hot content

const BlogListingPage = () => {
  return (
    <Layout renderName={false} allowExtraWide={false}>
      <SEO
        title="Blog Entries"
        keywords={[`data science`, `projects`, `computers`, `machine learning`]}
      />
      <div className="mw8 center">
        <div id="postlisting" className="pl3-l pa3 w-70-l center">
          <h1>Posts</h1>
          <PostListing />
        </div>
      </div>
    </Layout>
  )
}

export default BlogListingPage
