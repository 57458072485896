import React from "react"
import { Link } from "gatsby"

const PostLink = (props: {
  post: { frontmatter: { path: string; title: string; date: string } }
}) => (
  <div className="card w-100">
    <Link to={props.post.frontmatter.path}>
      {props.post.frontmatter.title} ({props.post.frontmatter.date})
    </Link>
  </div>
)

export default PostLink
